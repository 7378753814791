<template>
  <div class="page">
    <el-popover
        placement="bottom-end"
        width="600"
        :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
        v-model="visible">
      <el-form label-width="70px" label-position="left">
        <el-form-item label="搜索方式">
          <el-input size="small" v-model="searchObj[`${searchObj.searchWay}`]">
            <el-select style="width: 100px" v-model="searchObj.searchWay" slot="prepend" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams['list-search-field']" :label="index" :value="item"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker size="small"
              v-model="searchObj.time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <div style="display: flex;flex-direction: row">
          <el-form-item label="订单类型">
            <el-select size="small" v-model="searchObj.orderType" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams['order-type-list']" :value="item" :label="index"></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="订单状态">-->
<!--            <el-select size="small" v-model="searchObj.order_status" placeholder="请选择">-->
<!--              <el-option v-for="(item,index) in searchParams['order-status-list']" :value="item" :label="index"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="营销类型">-->
<!--            <el-select size="small" v-model="searchObj.marketType" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
        </div>
        <div style="display: flex;flex-direction: row">
          <el-form-item label="付款方式">
            <el-select size="small" v-model="searchObj.pay_channel" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams['payment-gateway-list']" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单来源">
            <el-select size="small" v-model="searchObj.source" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams['source-list']" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="结算状态">-->
<!--            <el-select size="small" v-model="searchObj.payState" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <el-button size="mini" type="primary" @click="getData">搜索</el-button>
          <el-button size="mini" @click="initSearch">重置</el-button>
          <el-button size="mini">导出订单商品</el-button>
          <el-button size="mini">导出订单</el-button>
        </div>
      </el-form>
      <el-button slot="reference">搜索</el-button>
    </el-popover>
    <el-tabs style="margin-top: 10px" v-model="searchObj.order_status" type="card" @tab-click="getData">
      <el-tab-pane label="全部订单" :name="'0'"></el-tab-pane>
      <el-tab-pane v-for="(item,index) in searchParams['order-status-list']" :label="index" :name="`${item}`"></el-tab-pane>
    </el-tabs>
    <listTable :orderList="list" :canChoosed="false" @chooseHandle="chooseHandle"/>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj['per-page']" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>

<script>
import listTable from '@/views/order/components/listTable.vue'
import {apiGetOrderList, apiGetOrderMix} from "@/request/api";
export default {
  name: "orderList",
  components: {
    listTable
  },
  data() {
    return {
      visible: false,
      pageCount: 0,
      searchObj: {},
      searchParams:{},
      pickerOptions:{
        shortcuts:[
          {
            text:'24小时',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近3天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text:'近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      list: [],
    }
  },
  created() {
    this.getSearchParams()
    this.initSearch()
    this.getData()
  },
  methods: {
    getSearchParams(){
      apiGetOrderMix({
        mix:'source-list|payment-gateway-list|order-status-list|order-type-list|list-search-field'
      }).then(res => {
        if(res.code == 200){
          this.searchParams = res.data;
        }
      })
    },
    pageChangeHandle(){
      this.getData()
    },
    onSearch(){
      this.searchObj.page = 1
      this.getData()
    },
    getData(){
      let data = {
        page:this.searchObj.page,
        'per-page':this.searchObj['per-page'],
        order_kind:this.searchObj.order_kind,
        order_state:this.searchObj.order_status,
        pay_channel:this.searchObj.pay_channel,
        source:this.searchObj.source,
        create_at_start:this.searchObj.time[0] || '',
        create_at_end:this.searchObj.time[1] || '',

      }
      if(this.searchObj.searchWay){
        data[`${this.searchObj.searchWay}`] = this.searchObj[`${this.searchObj.searchWay}`];
      }
      apiGetOrderList(data).then(res => {
        if(res.code == 200){
          this.pageCount = res.data.count;
          this.list = res.data.list;
        }
      })
    },
    initSearch(){
      this.searchObj = {
        searchWay: '',
        time: [],
        order_kind: '',
        order_status: 0,
        pay_channel: '',
        source: '',

        create_at_start:'',
        create_at_end:'',

        page:1,
        'per-page':10,
      }
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.pageView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.el-form-item{
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>